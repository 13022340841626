<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="안전보건관리계획서 개요" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn v-if="popupParam.saiVendorSafetyPlanId && editable" label="LBLREMOVE" icon="delete_forever" @btnClicked="removePlan" />
                <c-btn
                  v-show="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="saveType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="savePlan"
                  @btnCallback="saveCallback" 
                />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-plant 
                  required
                  :editable="editable" 
                  type="edit" 
                  name="plantCd" 
                  v-model="data.plantCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-vendor
                  required
                  :editable="editable"
                  :selfFlag="false"
                  label="협력업체"
                  name="vendorCd"
                  v-model="data.vendorCd">
                </c-vendor>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <!-- 관련공사 -->
                <c-construnction
                  :editable="editable"
                  name="sopConstructionId"
                  v-model="data.sopConstructionId">
                </c-construnction>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-textarea
                  :editable="editable"
                  :rows="2"
                  label="작업목표 및 방침"
                  name="workGoals"
                  v-model="data.workGoals">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-textarea
                  :editable="editable"
                  :rows="2"
                  label="업무분장"
                  name="workDivision"
                  v-model="data.workDivision">
                </c-textarea>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="안전관리계획" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-textarea
                  :editable="editable"
                  :rows="3"
                  label="작업내용"
                  name="workContents"
                  v-model="data.workContents">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-textarea
                  :editable="editable"
                  :rows="3"
                  label="위험성평가"
                  name="workRisk"
                  v-model="data.workRisk">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-textarea
                  :editable="editable"
                  :rows="3"
                  label="점검 및 모니터링"
                  name="workMonitoring"
                  v-model="data.workMonitoring">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-textarea
                  :editable="editable"
                  :rows="3"
                  label="안전교육"
                  name="workEdu"
                  v-model="data.workEdu">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-textarea
                  :editable="editable"
                  :rows="3"
                  label="작업허가"
                  name="workPermit"
                  v-model="data.workPermit">
                </c-textarea>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-card title="운영계획" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-textarea
                  :editable="editable"
                  :rows="3"
                  label="신호및 연락체계"
                  name="workContact"
                  v-model="data.workContact">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-textarea
                  :editable="editable"
                  :rows="3"
                  label="위험물질 및 설비"
                  name="workMaterial"
                  v-model="data.workMaterial">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <c-textarea
                  :editable="editable"
                  :rows="3"
                  label="비상대책"
                  name="workEmergency"
                  v-model="data.workEmergency">
                </c-textarea>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-table
            ref="grid"
            title="재해발생 수준"
            :columns="grid.columns"
            :gridHeight="grid.height"
            :data="data.calamitys"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            :isExcelDown="false"
            :isFullScreen="false"
            :hideBottom="true"
            :editable="editable"
            @table-data-change="tableDataChange"
          >
            <template slot="suffixTitle">
              <template>
                <font class="text-negative" style="font-size:0.8em;font-weight:300;">
                  ※ 최근 3년간 발생한 산업재해 현황
                </font>
              </template>
            </template>
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="관련파일" class="cardClassDetailForm" :collapsed="true">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <c-upload 
                  :attachInfo="attachInfo"
                  :editable="editable">
                </c-upload>
              </div>
            </template>
          </c-card>
        </div>
      </div>
    </q-form>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'safety-plan-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        saiVendorSafetyPlanId: '',
        copyFlag: false,
      }),
    },
  },
  data() {
    return {
      data: {
        saiVendorSafetyPlanId: '',  // 수급업체 안전보건관리계획서 일련번호
        plantCd: '',  // 사업장 코드
        vendorCd: '',  // 수급업체
        workGoals: '',  // 작업목표 및 방침
        workDivision: '',  // 업무분장
        workContents: '',  // 작업내용
        workRisk: '',  // 위험성평가
        workMonitoring: '',  // 점검 및 모니터링
        workEdu: '',  // 작업교육
        workPermit: '',  // 작업허가
        workContact: '',  // 신호및 연락체계
        workMaterial: '',  // 위험물질 및 설비
        workEmergency: '',  // 비상대책
        sopConstructionId: '',  // 공사 일련번호
        calamitys: [],
      },
      grid: {
        columns: [
          {
            name: 'year',
            field: 'year',
            label: '연도',
            align: 'center',
            style: 'width:20%',
            sortable: false,
          },
          {
            label: '부상자수',
            align: 'center',
            child: [
              {
                name: 'accidentCnt',
                field: 'accidentCnt',
                type: 'number',
                label: '사고성 재해',
                align: 'center',
                style: 'width:20%',
                sortable: false,
                disableTarget: 'editYn',
                disableCon: 'Y',
              },
              {
                name: 'diseaseCnt',
                field: 'diseaseCnt',
                type: 'number',
                label: '직업병',
                align: 'center',
                style: 'width:20%',
                sortable: false,
                disableTarget: 'editYn',
                disableCon: 'Y',
              },
            ]
          },
          {
            name: 'deadCnt',
            field: 'deadCnt',
            label: '사망자수',
            align: 'center',
            type: 'number',
            style: 'width:20%',
            sortable: false,
            disableTarget: 'editYn',
            disableCon: 'Y',
          },
          {
            name: 'totalCnt',
            field: 'totalCnt',
            label: '연도별 합계',
            align: 'right',
            type: 'cost',
            style: 'width:20%',
            sortable: false
          },
        ],
        height: '265px',
        data: [],
      },
      editable: true,
      detailUrl: '',
      saveUrl: 'transactionConfig.sai.vendor.safety.plan.insert.url',
      saveType: 'POST',
      isSave: false,
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      deleteYearUrl: '',
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'VENDOR_SAFETY_PLAN',
        taskKey: '',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.detailUrl = selectConfig.sai.vendor.safety.plan.get.url;
      this.insertUrl = transactionConfig.sai.vendor.safety.plan.insert.url;
      this.updateUrl = transactionConfig.sai.vendor.safety.plan.update.url;
      this.deleteUrl = transactionConfig.sai.vendor.safety.plan.delete.url;
      this.deleteYearUrl = transactionConfig.sai.vendor.safety.plan.calamit.url;
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.saiVendorSafetyPlanId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.saiVendorSafetyPlanId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          if (!this.popupParam.copyFlag) {
            this.$emit('setRegInfo', _result.data)
            this.$set(this.attachInfo, 'taskKey', _result.data.saiVendorSafetyPlanId)

            let _accidentCnt = 0;
            let _diseaseCnt = 0;
            let _deadCnt = 0;
            let _totalCnt = 0;
            this.$_.forEach(this.data.calamitys, _item => {
              if (_item.editYn == 'Y') {
                _accidentCnt += parseInt(_item.accidentCnt);
                _diseaseCnt += parseInt(_item.diseaseCnt);
                _deadCnt += parseInt(_item.deadCnt);
                _totalCnt += parseInt(_item.totalCnt);
              }
            })
            this.data.calamitys.push({
              saiVendorSafetyPlanId: '',
              year: '합계',
              accidentCnt: _accidentCnt,
              diseaseCnt: _diseaseCnt,
              deadCnt: _deadCnt,
              totalCnt: _totalCnt,
              editFlag: '',
              editYn: 'N',
            }) 
          } else {
            this.data.saiVendorSafetyPlanId = null;
            this.popupParam.saiVendorSafetyPlanId = null;
            this.data.calamitys = [];
            this.initPlanYear();
          }
        },);
      } else {
        this.initPlanYear();
      }
    },
    initPlanYear() {
      let years = [this.$comm.getThisYear()-3, this.$comm.getThisYear()-2, this.$comm.getThisYear()-1];
      this.$_.forEach(years, _item => {
        this.data.calamitys.push({
          saiVendorSafetyPlanId: uid(),
          year: _item,
          accidentCnt: 0,
          diseaseCnt: 0,
          deadCnt: 0,
          totalCnt: 0,
          editFlag: 'C',
          editYn: 'Y',
        }) 
      })
      this.data.calamitys.push({
        saiVendorSafetyPlanId: '',
        year: '합계',
        accidentCnt: 0,
        diseaseCnt: 0,
        deadCnt: 0,
        totalCnt: 0,
        editFlag: '',
        editYn: 'N',
      }) 
    },
    savePlan() {
      if (this.popupParam.saiVendorSafetyPlanId) {
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.saveType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId
              this.data.chgUserId = this.$store.getters.user.userId

              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$set(this.attachInfo, 'taskKey', _result.data)
      this.$set(this.attachInfo, 'isSubmit', uid())
      if (!this.popupParam.saiVendorSafetyPlanId) {
        this.popupParam.saiVendorSafetyPlanId = _result.data
      }
      this.popupParam.copyFlag = false;
      this.getDetail();
    },
    removePlan() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.saiVendorSafetyPlanId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    tableDataChange(props) {
      this.$set(props.row, 'totalCnt', parseInt(props.row['accidentCnt']) + parseInt(props.row['diseaseCnt']) + parseInt(props.row['deadCnt']))
      let _accidentCnt = 0;
      let _diseaseCnt = 0;
      let _deadCnt = 0;
      let _totalCnt = 0;
      this.$_.forEach(this.data.calamitys, _item => {
        if (_item.editYn == 'Y') {
          _accidentCnt += parseInt(_item.accidentCnt);
          _diseaseCnt += parseInt(_item.diseaseCnt);
          _deadCnt += parseInt(_item.deadCnt);
          _totalCnt += parseInt(_item.totalCnt);
        }
      })
      this.$set(this.data.calamitys[3], 'accidentCnt', _accidentCnt);
      this.$set(this.data.calamitys[3], 'diseaseCnt', _diseaseCnt);
      this.$set(this.data.calamitys[3], 'deadCnt', _deadCnt);
      this.$set(this.data.calamitys[3], 'totalCnt', _totalCnt);
    },
  }
};
</script>
